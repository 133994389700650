<template lang="html">
  <v-row
    align="center"
    style="min-height: 100vh"
    justify="center"
    class="fill-height"
  >
    <v-col>
      <v-alert
        dense
        v-show="success"
        text
        color="success"
        style="max-width: 400px; margin: 0 auto"
      >
        <div class="text-h6">Senha alterada com sucesso.</div>

        <p class="font-weight-regular mt-4">
          Sua senha foi alterada.
          <router-link to="/">Voltar para página inicial.</router-link>
        </p>
      </v-alert>
      <!-- <v-alert
        dense
        text
        type="success"
        style="max-width: 400px; margin: 0 auto"
        v-show="success"
        prominent
      >
        <div class="text-h6">Senha alterada com sucesso.</div>
        <p class="font-weight-regular mt-4" style="color: rgba(0, 0, 0, 0.87)">
          Sua senha foi alterada.
          <router-link to="/">Voltar para página inicial.</router-link>
        </p>
      </v-alert> -->
      <v-form @submit.prevent="send" class="text-center" v-show="!success">
        <img
          alt="Logo"
          style="max-height: 5.714rem; margin-bottom: 3rem"
          :src="organization.logo"
        />
        <div style="max-width: 330px; margin: 0 auto">
          <v-text-field
            label="Digite sua nova senha"
            v-model="form.password"
            name="password"
            dense
            ref="passwordInput"
            outlined
            autofocus
            type="password"
          >
          </v-text-field>
          <v-text-field
            label="Confirme sua nova senha"
            v-model="form.password_confirmation"
            name="password_confirmation"
            dense
            ref="passwordConfirmationInput"
            outlined
            type="password"
          >
          </v-text-field>

          <v-btn
            block
            type="submit"
            :loading="loading"
            elevation="1"
            color="primary"
          >
            Enviar
          </v-btn>
          <img
            alt="Logo"
            style="height: 3rem; margin-top: 2rem"
            src="@/assets/naja_logo_login.png"
          />
        </div>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import Api from '@/resources/NajaSocialApi'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      organization: state => state.data.organization,
    }),
  },

  data() {
    return {
      loading: false,
      success: false,
      form: {
        token: null,
        password: '',
        password_confirmation: '',
      },
    }
  },

  mounted() {
    this.form.token = this.$route.params.token
  },

  methods: {
    send() {
      this.loading = true

      Api.resetPassword(this.form)
        .then(() => {
          this.success = true
        })
        .catch(error => {
          if (error.response.status == 500) {
            this.$toast.show('Erro ao recuperar senha', 'error')
          } else if (error.response.status == 404) {
            this.$toast.show('Usuário não encontrado', 'error')
          } else {
            this.$toast.show(error.response.data.error, 'error')
          }
          throw error
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
